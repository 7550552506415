/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, Menu, ColumnWrapper, Image, MenuWrap, MenuButton, MenuWrapBox, MenuButtonClose, Button, ColumnWrap, Title, Text, PriceWrap, SeparateLine, SeparateLineWrap } from '@swp/components'


export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Bazen"}>
        <Column className="--menu pb--08 pt--08" style={{"backgroundColor":"rgba(255,255,255,1)"}} menu={true}>
          
          <Menu className="--right" style={{"maxWidth":1310}} fullscreen={false}>
            
            <ColumnWrapper className="menu-logo-box --center" style={{"maxWidth":310}}>
              
              <Image style={{"maxWidth":142}} alt={""} src={"https://cdn.swbpg.com/t/13078/048b88fe8f984ba5aab1738fe1364461_s=860x_.png"} svg={false} href={"/"} sizes="(max-width: 1439px) 100vw, 1440px" srcSet={"https://cdn.swbpg.com/t/13078/048b88fe8f984ba5aab1738fe1364461_s=350x_.png 350w, https://cdn.swbpg.com/t/13078/048b88fe8f984ba5aab1738fe1364461_s=660x_.png 660w, https://cdn.swbpg.com/t/13078/048b88fe8f984ba5aab1738fe1364461_s=860x_.png 860w, https://cdn.swbpg.com/t/13078/048b88fe8f984ba5aab1738fe1364461_s=1400x_.png 1400w, https://cdn.swbpg.com/t/13078/048b88fe8f984ba5aab1738fe1364461_s=2000x_.png 2000w"} content={null}>
              </Image>

            </ColumnWrapper>

            <MenuWrap >
              
              <MenuButton >
              </MenuButton>

              <MenuWrapBox className="--style4 --right">
                
                <MenuButtonClose >
                </MenuButtonClose>

                <Button className="btn-box btn-box--hvr2 btn-box--cbtn2 btn-box--sbtn2 btn-box--center ff--3 fs--16 w--600" href={"/kurzy"} target={""} content={"Kurzy"}>
                </Button>

                <Button className="btn-box btn-box--hvr2 btn-box--cbtn2 btn-box--sbtn2 btn-box--center ff--3 fs--16 w--600" href={"/zajezdy"} target={""} content={"Zájezdy"}>
                </Button>

                <Button className="btn-box btn-box--hvr2 btn-box--cbtn2 btn-box--sbtn2 btn-box--center ff--3 fs--16 w--600" href={"/obchod"} target={""} content={"Obchod"}>
                </Button>

                <Button className="btn-box btn-box--hvr2 btn-box--cbtn2 btn-box--sbtn2 btn-box--center ff--3 fs--16 w--600" href={"/sluzby"} target={""} content={"Služby"}>
                </Button>

                <Button className="btn-box btn-box--hvr2 btn-box--cbtn2 btn-box--sbtn2 btn-box--center ff--3 fs--16 w--600" href={"/bazen"} target={""} content={"Bazén"}>
                </Button>

                <Button className="btn-box btn-box--hvr2 btn-box--cbtn2 btn-box--sbtn2 btn-box--center ff--3 fs--16 w--600" href={"/klub"} target={""} content={"Klub"}>
                </Button>

                <Button className="btn-box btn-box--hvr2 btn-box--cbtn2 btn-box--sbtn2 btn-box--center ff--3 fs--16 w--600" href={"/o-nas"} target={""} content={"O nás"}>
                </Button>

                <Button className="btn-box btn-box--hvr2 btn-box--cbtn2 btn-box--sbtn2 btn-box--center ff--3 fs--16 w--600" href={"/fotogalerie"} target={""} content={"Fotogalerie"}>
                </Button>

                <Button className="btn-box btn-box--hvr2 btn-box--cbtn2 btn-box--sbtn2 btn-box--center ff--3 fs--16 w--600" href={"/kontakt"} target={""} content={"Kontakt"}>
                </Button>

              </MenuWrapBox>

            </MenuWrap>

          </Menu>

        </Column>


        <Column className="--center" style={{"paddingTop":15,"paddingBottom":15.033332824707031}} name={"sjsvadrt99"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box title-box--center ff--3" content={"Bazén Zelený pruh"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--center" style={{"paddingTop":20,"paddingBottom":23.125}} name={"k1nue38c22j"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--center" style={{"marginTop":0}}>
              
              <Text className="text-box ff--3" style={{"maxWidth":654,"paddingTop":0}} content={"Potápění na plaveckém bazénu Zelený pruh každé úterý od 20:00 do 21:00 hod."}>
              </Text>

              <Text className="text-box fs--20" content={"<span style=\"font-weight: bold;\">Do letních prázdnin poslední 2 bazény: 11. a 18. 6.</span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--center" style={{"paddingTop":3,"paddingBottom":10.125}} name={"uwwzeyudh4s"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box title-box--center ff--3 fs--22" content={"Ceník"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column style={{"paddingTop":0,"paddingBottom":25.66668701171875}} name={"9gfoxoz2ga4"}>
          
          <ColumnWrap className="column__flex el--2" columns={"2"}>
            
            <ColumnWrapper >
              
              <PriceWrap style={{"backgroundColor":"#f4f4f4"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box ff--3" content={"Pro členy klubu Orca<br>"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap style={{"backgroundColor":"#f4f4f4"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box ff--3" content={"<font color=\"rgba(0,0,0,1)\">Vstup na bazén<br></font>"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box ff--3" content={"<font color=\"rgba(0,0,0,1)\">100,-<br></font>"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap style={{"backgroundColor":"#f4f4f4"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box ff--3" content={"<font color=\"rgba(0,0,0,1)\">Zapůjčení naplněné lahve<br></font>"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box ff--3" content={"<font color=\"rgba(0,0,0,1)\">100,-</font>"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap style={{"backgroundColor":"#f4f4f4"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box ff--3" content={"<font color=\"rgba(0,0,0,1)\">Každá součást výstroje<br></font>"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box ff--3" content={"<font color=\"rgba(0,0,0,1)\">50,-</font>"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap style={{"backgroundColor":"#f4f4f4"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box ff--3" content={"<font color=\"rgba(0,0,0,1)\">Olova, maska, ploutve<br></font>"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box ff--3" content={"<font color=\"rgba(0,0,0,1)\">zdarma</font>"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <PriceWrap style={{"backgroundColor":"#f4f4f4"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box ff--3" content={"Pro veřejnost<br>"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap style={{"backgroundColor":"#f4f4f4"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box ff--3" content={"<font color=\"rgba(0,0,0,1)\">Vstup na bazén<br></font>"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box ff--3" content={"<font color=\"rgba(0,0,0,1)\">200,-<br></font>"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap style={{"backgroundColor":"#f4f4f4"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box ff--3" content={"<font color=\"rgba(0,0,0,1)\">Zapůjčení naplněné lahve<br></font>"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box ff--3" content={"<font color=\"rgba(0,0,0,1)\">100,-</font>"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap style={{"backgroundColor":"#f4f4f4"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box ff--3" content={"<font color=\"rgba(0,0,0,1)\">Každá součást výstroje<br></font>"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box ff--3" content={"<font color=\"rgba(0,0,0,1)\">100,-</font>"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap style={{"backgroundColor":"#f4f4f4"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box ff--3" content={"<font color=\"rgba(0,0,0,1)\">Olova, maska, ploutve<br></font>"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box ff--3" content={"<font color=\"rgba(0,0,0,1)\">zdarma</font>"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--center" style={{"paddingTop":22,"paddingBottom":29.024993896484375}} name={"169sxg7kzqki"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--center">
              
              <Text className="text-box ff--3" style={{"maxWidth":718}} content={"<font color=\"rgba(0,0,0,1)\">Podmínkou pro čerpání výhod </font><a href=\"/klub#klubclen\">klubu ORCA</a><font color=\"rgba(0,0,0,1)\"> je zaplacení celého ročního příspěvku 2.000 Kč, u nových členů jeho poměrné části.\n\n<br><br>ZAPŮJČENÍ VÝSTROJE JE NUTNÉ DOMLUVIT V ORCE tel.: 244468220, nebo 603310054.<br><br>Kurzisté mají samozřejmě vstup na bazén a půjčovné v ceně kurzu.\n</font>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column style={{"paddingTop":0,"paddingBottom":0}} name={"d4vewhuplba"} layout={"l13"}>
          
          <ColumnWrap className="column__flex --left el--2 flex--center" style={{"maxWidth":867}} columns={"2"}>
            
            <ColumnWrapper className="--left" style={{"maxWidth":480}}>
              
              <Title className="title-box ff--3 fs--26" content={"Jak na bazén<br>"}>
              </Title>

              <Text className="text-box ff--3" content={"<font color=\"rgba(0,0,0,1)\">Adresa bazénu: <br></font><span style=\"color: rgb(0, 0, 0);\">Zelený pruh 1294, 147 08, Praha 4</span><font color=\"rgba(0,0,0,1)\"><br><br>Jet můžete buďto s námi od Orcy (místa jsou omezena, je nutné předem zavolat, jestli se vejdete) - odjíždíme po přednášce v 19:00 hod., a nebo svojí vlastní dopravou. Sraz je ve vestibulu bazénu v 19:30 hod.\n</font>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--right">
              
              <Image style={{"maxWidth":391}} src={"https://cdn.swbpg.com/t/13078/22d595c3656b44a2a7e838009e2c80fb_s=660x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/13078/22d595c3656b44a2a7e838009e2c80fb_s=350x_.jpg 350w, https://cdn.swbpg.com/t/13078/22d595c3656b44a2a7e838009e2c80fb_s=660x_.jpg 660w, https://cdn.swbpg.com/t/13078/22d595c3656b44a2a7e838009e2c80fb_s=860x_.jpg 860w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <SeparateLine className="pb--10 pt--10" name={"57ot3ra9sx2"}>
          
          <SeparateLineWrap >
          </SeparateLineWrap>

        </SeparateLine>


        <Column style={{"paddingTop":1,"paddingBottom":16.8515625}} name={"bazenmelnik"}>
          
          <ColumnWrap className="column__flex el--1" columns={"1"}>
            
            <ColumnWrapper style={{"maxWidth":1197}}>
              
              <Title className="title-box title-box--center ff--3" content={"Bazén Mělník"}>
              </Title>

              <Text className="text-box text-box--center ff--3" content={"<span style=\"color: rgb(6, 6, 6);\">Každou poslední neděli v lednu, únoru a březnu nás najdete na bazénu v Mělníku, kde si můžete vyzkoušet nové věci, nebo potrénovat nově získané dovednosti.<br></span><br>Termín: <span style=\"color: rgb(0, 0, 0);\">Neděle od 10 do 12 hodin</span><br>Cena: <span style=\"color: rgb(0, 0, 0);\">Vstupné do bazénu 100 Kč / hodinu, půjčovné výstroje dle <a href=\"/sluzby#pujcovna\">ceníku</a></span><br><br>"}>
              </Text>

              <Text className="text-box text-box--center ff--3" content={"Rozvrh tematických bazénů:<br><br><span style=\"color: rgb(0, 0, 0);\">Neděle 28. 1. 2024 - potápění v suchém obleku (obleky k vyzkoušení zapůjčené firmou Agama)</span><br><br><span style=\"color: rgb(0, 0, 0);\">Neděle 25. 2. 2024 - potápění se sidemountem nebo stage</span><br><br><span style=\"color: rgb(0, 0, 0);\">Neděle 31. 3. 2024 - potápění s dvojčetem<br></span><br><span style=\"color: rgb(0, 0, 0);\">Na všech bazénech si s námi můžete potrénovat i vypouštění bójky, nebo třeba couvání.</span><br><br><span style=\"color: rgb(0, 0, 0);\">Veškerou výstroj Vám můžeme zapůjčit. K dispozici Vám budou instruktoři a divemasteři, kteří danou výstroj sami aktivně používají.</span><br><br><span style=\"color: rgb(229, 13, 13);\">Účast a případné zapůjčení výstroje je třeba nahlásit předem v Orce (e-mailem, nebo telefonicky).</span><br><br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column style={{"paddingTop":0,"paddingBottom":0}} name={"d4vewhuplba"} layout={"l13"}>
          
          <ColumnWrap className="column__flex --left el--2 flex--center" style={{"maxWidth":867}} columns={"2"}>
            
            <ColumnWrapper className="--left" style={{"maxWidth":480}}>
              
              <Title className="title-box ff--3 fs--26" content={"Jak na bazén<br>"}>
              </Title>

              <Text className="text-box ff--3" content={"<span style=\"color: rgb(0, 0, 0);\">Adresa bazénu: <br>Krytý plavecký bazén Mělník<br>ul. Řipská 648<br>276 01 Mělník</span><font color=\"rgba(0,0,0,1)\"><br></font>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--right">
              
              <Image style={{"maxWidth":391}} src={"https://cdn.swbpg.com/t/13078/3d2bd159ddd44c10aae9230678bfb2a7_s=350x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/13078/3d2bd159ddd44c10aae9230678bfb2a7_s=350x_.jpg 350w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-1b4itn5" style={{"marginTop":17,"paddingTop":30,"paddingBottom":7.6171875}} layout={"l1"} css={css`
      
    background-image: linear-gradient(rgba(255,255,255,0.65), rgba(255,255,255,0.65)), url(https://cdn.swbpg.com/t/13078/9a254d9c36af49c3bbd19ff443ad56fe_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(rgba(255,255,255,0.65), rgba(255,255,255,0.65)), url(https://cdn.swbpg.com/t/13078/9a254d9c36af49c3bbd19ff443ad56fe_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(rgba(255,255,255,0.65), rgba(255,255,255,0.65)), url(https://cdn.swbpg.com/t/13078/9a254d9c36af49c3bbd19ff443ad56fe_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(rgba(255,255,255,0.65), rgba(255,255,255,0.65)), url(https://cdn.swbpg.com/t/13078/9a254d9c36af49c3bbd19ff443ad56fe_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(rgba(255,255,255,0.65), rgba(255,255,255,0.65)), url(https://cdn.swbpg.com/t/13078/9a254d9c36af49c3bbd19ff443ad56fe_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(rgba(255,255,255,0.65), rgba(255,255,255,0.65)), url(https://cdn.swbpg.com/t/13078/9a254d9c36af49c3bbd19ff443ad56fe_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(rgba(255,255,255,0.65), rgba(255,255,255,0.65)), url(https://cdn.swbpg.com/t/13078/9a254d9c36af49c3bbd19ff443ad56fe_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(rgba(255,255,255,0.65), rgba(255,255,255,0.65)), url(https://cdn.swbpg.com/t/13078/9a254d9c36af49c3bbd19ff443ad56fe_s=3000x_.jpg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box ff--3 title-box--invert mt--0" content={"<span style=\"color: rgb(0, 0, 0);\">Orca diving, s.r.o.</span>"}>
              </Title>

              <Text className="text-box ff--3 text-box--invert" style={{"marginBottom":0,"paddingBottom":0}} content={"<span style=\"color: rgb(0, 0, 0);\">Ledařská 433/9, Praha 4, 147 00, Česká Republika</span>"}>
              </Text>

              <Image style={{"maxWidth":236,"marginTop":16.6875,"paddingTop":0}} alt={""} src={"https://cdn.swbpg.com/o/13078/000a415e28ce44c5972c4f22d2c183db.PNG"} svg={false} sizes="(max-width: 1439px) 100vw, 1440px" srcSet={""} content={null}>
              </Image>

              <Image style={{"maxWidth":36,"marginTop":0,"paddingTop":10,"paddingBottom":0}} alt={""} src={"https://cdn.swbpg.com/t/13078/7555fba4134a4a698b9f5797f5549d68_e=63x61x388x388_s=350x_.png"} svg={false} url={"https://www.facebook.com/orcadivingpotapeni"} href={"https://www.facebook.com/orcadivingpotapeni"} sizes="(max-width: 1439px) 100vw, 1440px" srcSet={"https://cdn.swbpg.com/t/13078/7555fba4134a4a698b9f5797f5549d68_e=63x61x388x388_s=350x_.png 350w"} content={null}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}